import axios from 'axios'
import router from '../router'

export default {

    install: (app, options) => {                
        var my_instance = axios.create({
            baseURL: options.baseUrl,
            headers: {
                "Content-Type":"application/json",
                "Accept": 'application/json',              
            }
        });

        my_instance.interceptors.request.use(
          (config) => {
            const token=localStorage.getItem("access_token");
            if(token!=""){
              config.headers['Authorization'] = "Bearer "+token; 
            }            
            return config;
          },
          (error) => {
            return Promise.reject(error)            
          });
          
        my_instance.interceptors.response.use(
          (response) => {
            return response;
          },
          (error) => {
            
            if (error?.response?.status === 403) {
              //console.log("403 Forbidden");
              return Promise.reject(error.response?.data??error);
            }

            else if (error?.response?.status === 401) {
              //console.log("401 Unauthorized");
              router.push({name:"Login"});
              return Promise.reject(error.response?.data??error);
            }
            else if (error?.response?.status === 422) {
              return Promise.reject(error.response?.data??error);
            } 
            else if (error?.response?.status === 500) {
              return Promise.reject(error.response?.data??error);
            }             
            return Promise.reject(error);
          }); 
         /* 
        //on first page load,
        //if access_token exists but not valid
        my_instance.get('/auth/me').then(response => {          
            response;            
        }).catch(error => {
            error;
            router.push({name:"Login"});
        });          
       */
        app.config.globalProperties.$axios=my_instance;
    }
}
