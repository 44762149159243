// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from './plugins/axios'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/plugins/global/plugins.bundle.css'
import './assets/css/style.bundle.css'
import './assets/css/custom.css'
import './assets/css/skins/header/base/light.css'
import './assets/css/skins/header/menu/light.css'
import './assets/css/skins/brand/dark.css'
import './assets/css/skins/aside/dark.css'
import './assets/css/pages/login/login-2.css'

//import MyPlugin from './plugins/MyPlugin';



//import scripts from './assets/plugins/global/plugins.bundle.js'
//import scripts2 from './assets/js/scripts.bundle.js'


var MyApp = createApp(App);
MyApp.config.globalProperties.isAuthenticated = false;
MyApp.config.globalProperties.ws_url = process.env.NODE_ENV === 'production' ? '/ws/api' : '/api';
MyApp.use(axios,{
    baseUrl: process.env.NODE_ENV === 'production' ? '/ws/api' : '/api',
});
MyApp.use(router)
//.use(MyPlugin)
.mount('#app');  


