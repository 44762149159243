<script>
import DataLoading from './../components/DataLoading.vue'
import PageAlert from './../components/PageAlert.vue'

export default {
  name: 'UserChangePasswordForm',
  components: {
    DataLoading,
    PageAlert
  },  
  data(){
    return {
      loading:false,
      row:{},
      MyPageAlert:{"status":"","message":""}
    }
  },

  mounted(){
    
  },
  methods: {

    async changePassword() {
      try {
        this.loading=true;
        const response = await this.$axios.post("/users/changePassword",this.row);
        response;

        this.MyPageAlert.status="success";
        this.MyPageAlert.message=response?.data?.message;
        this.loading=false;
        
      } catch (error) {
        this.MyPageAlert.status="error";
        this.MyPageAlert.message=error.message;
        this.loading=false;
      }         

    }
    
     
   }  
      
}
</script>
<template>
<!--begin::sifre_degistir-->
<div class="modal fade" id="sifre_degistir" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
   <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Şifre Değiştir</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            </button>
         </div>
         <div class="modal-body">
           <PageAlert/>
           <DataLoading v-if="loading"/> 
       
         
            <div v-if="MyPageAlert.status!='success'" class="kt-section__body">
               <div class="form-group row">
                  <label class="col-md-4 col-form-label c_bold">Mevcut Şifreniz</label>
                  <div class="col-md-8">
                     <input type="password" name="old_password" class="form-control" v-model="row.old_password" placeholder="">
                  </div>
               </div>
               <div class="form-group row">
                  <label class="col-md-4 col-form-label c_bold">Yeni Şifre</label>
                  <div class="col-md-8">
                     <input type="password" name="password" class="form-control" v-model="row.password" placeholder="">
                  </div>
               </div>
               <div class="form-group form-group-last row">
                  <label class="col-md-4 col-form-label c_bold">Şifre Onayı</label>
                  <div class="col-md-8">
                     <input type="password"  name="password_confirmation" class="form-control" v-model="row.password_confirmation" placeholder="">
                  </div>
               </div>
            </div>
         </div>
         <div  v-if="MyPageAlert.status!='success'" class="modal-footer" >
            <button type="button" class="btn btn-warning" data-bs-dismiss="modal"><i class="la la-close"></i> Kapat</button>
            <button  @click="changePassword()" type="submit" class="btn btn-danger"><i class="la la-refresh"></i> Değiştir</button>
         </div>
      </div>
   </div>
</div>
<!--end::sifre_degistir-->
</template>