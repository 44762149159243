<script>
import UserChangePasswordForm from './../components/UserChangePasswordForm.vue'

export default {
  name: 'FooterSection',
  components: {
    UserChangePasswordForm
  }, 
}
</script>
<template>
<div></div>
<UserChangePasswordForm/>
</template>